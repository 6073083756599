.login-page-container {
  height: 100vh;
  background-color: 'white';
}

.login-page-subtitle {
  margin-top: 80px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: 25px;
}

.ant-pro-form-login-page-top {
  width: 328px;
  margin: 0 auto;
}

.login-page-footer-logo {
  max-height: 70px;
  margin-top: 5px;
}

.login-page-footer {
  display: flex;
  flex-direction: column;
}

.login-page-footer-logo {
  margin-top: 20px;
}

@media (min-width: 800px) {
  .login-page-footer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 470px);
    justify-content: space-between;
  }

  .login-page-footer-logo {
    margin-top: 250px;
  }
}

@media (max-width: 800px) {
  .ant-pro-form-login-page-notice-activity,
  .ant-pro-form-login-page-container {
    max-width: none !important;
  }
}
